@charset "UTF-8";

/* */

.slice-widget {
    border-radius: 8px !important;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;

    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

@media (min-width: 576px){

    .slice-widget {
        flex-direction: column;
    }
}

.slice-widget .slice-image-wrapper {
    width: 35%;
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 8px;
    padding-inline-start: 8px;
    -webkit-padding-end: 8px;
    padding-inline-end: 8px;
}

.slice-widget .slice-image-wrapper .slice-image {
    display: block;
    margin: 0 auto;
    width: 100%;
}

@media (min-width: 576px){

    .slice-widget .slice-image-wrapper {
        width: auto;
    }

    .slice-widget .slice-image-wrapper .slice-image {
        width: 70%;
    }
}

.slice-widget ion-card-content {
    width: 65%;
    padding-top: 6px;
    padding-bottom: 6px;
    -webkit-padding-start: 8px;
    padding-inline-start: 8px;
    -webkit-padding-end: 12px;
    padding-inline-end: 12px;
}

@media (min-width: 480px){

    .slice-widget ion-card-content {
        -webkit-padding-start: 16px;
        padding-inline-start: 16px;
        -webkit-padding-end: 16px;
        padding-inline-end: 16px;
    }
}

@media (min-width: 576px){

    .slice-widget ion-card-content {
        width: 100%;
        align-self: flex-start;
    }
}

.slice-widget .slice-title,
.slice-widget .slice-weight,
.slice-widget .slice-price {
    font-family: "Mulish","sans-serif";
}

.slice-widget .slice-title {
    line-height: 1.5em;
    margin-bottom: 0.8rem;
    text-transform: none;
}

.slice-widget .slice-weight,
.slice-widget .slice-price {
    font-weight: 600;
}

[data-theme="dark"] .slice-widget .slice-weight,
[data-theme="dark"] .slice-widget .slice-price {
    font-weight: 500;
}

.slice-widget .slice-weight,
.slice-widget .slice-price {
    /* color: var(--ion-color-danger) !important; */
    color: #d89031 !important;
}

.slice-widget .slice-widget-buttons-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    border-radius: 5px;
    width: 100%;
}


[data-theme="dark"] .slice-widget .slice-widget-buttons-wrapper {
    background-color: #1a1a1a;
    color: #ffffff;
}

[data-theme="light"] .slice-widget .slice-widget-buttons-wrapper {
    background-color: #f2f2f2;
    color: #1a1a1a;
}

@media (min-width: 576px){

    .slice-widget .slice-widget-buttons-wrapper,
    [data-theme="light"] .slice-widget .slice-widget-buttons-wrapper {
        /* justify-content: center; */
    }
}

.slice-widget .slice-widget-buttons-wrapper ion-button::part(native) {
    padding: 8px !important;
}

[data-theme="dark"] .slice-widget .slice-widget-buttons-wrapper ion-button::part(native) {
    color: #ffffff;
}

[data-theme="light"] .slice-widget .slice-widget-buttons-wrapper ion-button::part(native) {
    color: #1a1a1a;
}

.slice-widget .slice-widget-buttons-wrapper ion-button svg {
    width: 1.3rem;
    height: 1.3rem;
}

/* .slice-widget.cart-slice-widget .slice-widget-buttons-wrapper .box-quantity {
    margin: 0;
    padding: 3px 10px;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: var(--ion-color-primary) !important;
} */

.slice-widget .slice-widget-buttons-wrapper input[type="number"] {
    background-color: transparent;
    border: none;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    outline: none;
    width: 50px;
    text-align: center;
    margin: 0;
    /* padding: 3px 10px; */
}

[data-theme="dark"] .slice-widget .slice-widget-buttons-wrapper input[type="number"] {
    color: var(--ion-color-primary);
}

[data-theme="light"] .slice-widget .slice-widget-buttons-wrapper input[type="number"] {
    color: var(--ion-color-primary);
}

/* Retirer les flèches des inputs number */

/* Firefox */

.slice-widget .slice-widget-buttons-wrapper input[type="number"] {
    -moz-appearance: textfield;
}

/* Chrome */

.slice-widget .slice-widget-buttons-wrapper input[type="number"]::-webkit-inner-spin-button,
.slice-widget .slice-widget-buttons-wrapper input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Opéra */

.slice-widget .slice-widget-buttons-wrapper input[type="number"]::-o-inner-spin-button,
.slice-widget .slice-widget-buttons-wrapper input[type="number"]::-o-outer-spin-button { 
	-o-appearance: none;
	margin:0
}
