@charset "UTF-8";

/* */

#homePageContent.ion-padding {
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-top: 12px;
    --padding-bottom: 12px;
    padding-inline-start: 12px;
    padding-inline-end: 12px;
}

/* */

.section-title-wrapper {
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
}

.section-title-wrapper .section-title {
    font-weight: 700;
    font-size: 20px !important;
}

[data-theme="dark"] .section-title-wrapper .section-title {
    font-weight: 600;
}

.card.home-menu-item-card {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.8em;
}

@media (min-width: 992px){
    .card.home-menu-item-card {
        min-height: 150px;
    }
}

@media (min-width: 1200px){
    .card.home-menu-item-card {
        min-height: 200px;
    }
}

@media (min-width: 1680px){
    .card.home-menu-item-card {
        min-height: 280px;
    }
}

.card.home-menu-item-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.75;
}

.card.home-menu-item-card::part(native) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.card.home-menu-item-card .card-body{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.card.home-menu-item-card .card-title{
    color: #fff;
    font-family: "Mulish","sans-serif";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: none;
}

/*  */

.lcEcLb{
    bottom: 5% !important;
}
