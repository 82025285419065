@charset "UTF-8";

/* */

.fade-in-up {
    animation-name: fadeInUp;
}

.animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
}

/* */

@keyframes rotateSmooth {
    0% {
        -ms-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* */

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -ms-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        opacity: 1;
        -ms-transform: none;
        -moz-transform: none;
        -o-transform: none;
        -webkit-transform: none;
        transform: none;
    }
}

/* */

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
