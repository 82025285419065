@charset "UTF-8";

/* */

ion-content#successfulOrderPageContent {
    --background: rgba(var(--ion-background-color-rgb), 0.7);
}

.successful-order,
.successful-order ion-button {
    font-family: "Mulish", "sans-serif";
}

.successful-order .image-wrapper {
    width: 180px;
}

.successful-order .image-wrapper img {
    width: 100%;
    display: inline-block;
}

.successful-order .successful-order-title {
    font-family: "Quicksand", "sans-serif";
}

.successful-order .successful-order-title,
.successful-order .successful-order-text {
    line-height: 1.6rem;
}

[data-theme='light'] .successful-order .successful-order-text {
    font-weight: 500;
}
