@charset "UTF-8";

/* */

.password-meter-control .password-meter-control-element {
    flex-grow: 1 !important;
    background-color: rgba(var(--ion-color-medium-rgb), 0.39);
    border-radius: 0.475rem;
}

.password-meter-control .password-meter-control-element.active {
    background-color: rgba(var(--ion-color-success-rgb), 0.76);
}
