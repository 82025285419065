@charset "UTF-8";

/* All  */

.m-auto {
    margin: auto !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 0.75rem !important;
}

.m-4 {
    margin: 1rem !important;
}

.m-5 {
    margin: 1.25rem !important;
}

.m-6 {
    margin: 1.5rem !important;
}

.m-7 {
    margin: 1.75rem !important;
}

.m-8 {
    margin: 2rem !important;
}

.m-9 {
    margin: 2.25rem !important;
}

.m-10 {
    margin: 2.5rem !important;
}

.m-11 {
    margin: 2.75rem !important;
}

.m-12 {
    margin: 3rem !important;
}

.m-13 {
    margin: 3.25rem !important;
}

.m-14 {
    margin: 3.5rem !important;
}

.m-15 {
    margin: 3.75rem !important;
}

.m-16 {
    margin: 4rem !important;
}

.m-17 {
    margin: 4.25rem !important;
}

.m-18 {
    margin: 4.5rem !important;
}

.m-19 {
    margin: 4.75rem !important;
}

.m-20 {
    margin: 5rem !important;
}

@media (min-width: 576px) {
    .m-sm-auto {
        margin: auto !important;
    }
    
    .m-sm-0 {
        margin: 0 !important;
    }
    
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    
    .m-sm-3 {
        margin: 0.75rem !important;
    }
    
    .m-sm-4 {
        margin: 1rem !important;
    }
    
    .m-sm-5 {
        margin: 1.25rem !important;
    }
    
    .m-sm-6 {
        margin: 1.5rem !important;
    }
    
    .m-sm-7 {
        margin: 1.75rem !important;
    }
    
    .m-sm-8 {
        margin: 2rem !important;
    }
    
    .m-sm-9 {
        margin: 2.25rem !important;
    }
    
    .m-sm-10 {
        margin: 2.5rem !important;
    }
    
    .m-sm-11 {
        margin: 2.75rem !important;
    }
    
    .m-sm-12 {
        margin: 3rem !important;
    }
    
    .m-sm-13 {
        margin: 3.25rem !important;
    }
    
    .m-sm-14 {
        margin: 3.5rem !important;
    }
    
    .m-sm-15 {
        margin: 3.75rem !important;
    }
    
    .m-sm-16 {
        margin: 4rem !important;
    }
    
    .m-sm-17 {
        margin: 4.25rem !important;
    }
    
    .m-sm-18 {
        margin: 4.5rem !important;
    }
    
    .m-sm-19 {
        margin: 4.75rem !important;
    }
    
    .m-sm-20 {
        margin: 5rem !important;
    }
}

@media (min-width: 768px) {
    .m-md-auto {
        margin: auto !important;
    }
    
    .m-md-0 {
        margin: 0 !important;
    }
    
    .m-md-1 {
        margin: 0.25rem !important;
    }
    
    .m-md-2 {
        margin: 0.5rem !important;
    }
    
    .m-md-3 {
        margin: 0.75rem !important;
    }
    
    .m-md-4 {
        margin: 1rem !important;
    }
    
    .m-md-5 {
        margin: 1.25rem !important;
    }
    
    .m-md-6 {
        margin: 1.5rem !important;
    }
    
    .m-md-7 {
        margin: 1.75rem !important;
    }
    
    .m-md-8 {
        margin: 2rem !important;
    }
    
    .m-md-9 {
        margin: 2.25rem !important;
    }
    
    .m-md-10 {
        margin: 2.5rem !important;
    }
    
    .m-md-11 {
        margin: 2.75rem !important;
    }
    
    .m-md-12 {
        margin: 3rem !important;
    }
    
    .m-md-13 {
        margin: 3.25rem !important;
    }
    
    .m-md-14 {
        margin: 3.5rem !important;
    }
    
    .m-md-15 {
        margin: 3.75rem !important;
    }
    
    .m-md-16 {
        margin: 4rem !important;
    }
    
    .m-md-17 {
        margin: 4.25rem !important;
    }
    
    .m-md-18 {
        margin: 4.5rem !important;
    }
    
    .m-md-19 {
        margin: 4.75rem !important;
    }
    
    .m-md-20 {
        margin: 5rem !important;
    }
}

@media (min-width: 992px) {
    .m-lg-auto {
        margin: auto !important;
    }
    
    .m-lg-0 {
        margin: 0 !important;
    }
    
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    
    .m-lg-3 {
        margin: 0.75rem !important;
    }
    
    .m-lg-4 {
        margin: 1rem !important;
    }
    
    .m-lg-5 {
        margin: 1.25rem !important;
    }
    
    .m-lg-6 {
        margin: 1.5rem !important;
    }
    
    .m-lg-7 {
        margin: 1.75rem !important;
    }
    
    .m-lg-8 {
        margin: 2rem !important;
    }
    
    .m-lg-9 {
        margin: 2.25rem !important;
    }
    
    .m-lg-10 {
        margin: 2.5rem !important;
    }
    
    .m-lg-11 {
        margin: 2.75rem !important;
    }
    
    .m-lg-12 {
        margin: 3rem !important;
    }
    
    .m-lg-13 {
        margin: 3.25rem !important;
    }
    
    .m-lg-14 {
        margin: 3.5rem !important;
    }
    
    .m-lg-15 {
        margin: 3.75rem !important;
    }
    
    .m-lg-16 {
        margin: 4rem !important;
    }
    
    .m-lg-17 {
        margin: 4.25rem !important;
    }
    
    .m-lg-18 {
        margin: 4.5rem !important;
    }
    
    .m-lg-19 {
        margin: 4.75rem !important;
    }
    
    .m-lg-20 {
        margin: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-auto {
        margin: auto !important;
    }
    
    .m-xl-0 {
        margin: 0 !important;
    }
    
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    
    .m-xl-3 {
        margin: 0.75rem !important;
    }
    
    .m-xl-4 {
        margin: 1rem !important;
    }
    
    .m-xl-5 {
        margin: 1.25rem !important;
    }
    
    .m-xl-6 {
        margin: 1.5rem !important;
    }
    
    .m-xl-7 {
        margin: 1.75rem !important;
    }
    
    .m-xl-8 {
        margin: 2rem !important;
    }
    
    .m-xl-9 {
        margin: 2.25rem !important;
    }
    
    .m-xl-10 {
        margin: 2.5rem !important;
    }
    
    .m-xl-11 {
        margin: 2.75rem !important;
    }
    
    .m-xl-12 {
        margin: 3rem !important;
    }
    
    .m-xl-13 {
        margin: 3.25rem !important;
    }
    
    .m-xl-14 {
        margin: 3.5rem !important;
    }
    
    .m-xl-15 {
        margin: 3.75rem !important;
    }
    
    .m-xl-16 {
        margin: 4rem !important;
    }
    
    .m-xl-17 {
        margin: 4.25rem !important;
    }
    
    .m-xl-18 {
        margin: 4.5rem !important;
    }
    
    .m-xl-19 {
        margin: 4.75rem !important;
    }
    
    .m-xl-20 {
        margin: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .m-xxl-auto {
        margin: auto !important;
    }
    
    .m-xxl-0 {
        margin: 0 !important;
    }
    
    .m-xxl-1 {
        margin: 0.25rem !important;
    }
    
    .m-xxl-2 {
        margin: 0.5rem !important;
    }
    
    .m-xxl-3 {
        margin: 0.75rem !important;
    }
    
    .m-xxl-4 {
        margin: 1rem !important;
    }
    
    .m-xxl-5 {
        margin: 1.25rem !important;
    }
    
    .m-xxl-6 {
        margin: 1.5rem !important;
    }
    
    .m-xxl-7 {
        margin: 1.75rem !important;
    }
    
    .m-xxl-8 {
        margin: 2rem !important;
    }
    
    .m-xxl-9 {
        margin: 2.25rem !important;
    }
    
    .m-xxl-10 {
        margin: 2.5rem !important;
    }
    
    .m-xxl-11 {
        margin: 2.75rem !important;
    }
    
    .m-xxl-12 {
        margin: 3rem !important;
    }
    
    .m-xxl-13 {
        margin: 3.25rem !important;
    }
    
    .m-xxl-14 {
        margin: 3.5rem !important;
    }
    
    .m-xxl-15 {
        margin: 3.75rem !important;
    }
    
    .m-xxl-16 {
        margin: 4rem !important;
    }
    
    .m-xxl-17 {
        margin: 4.25rem !important;
    }
    
    .m-xxl-18 {
        margin: 4.5rem !important;
    }
    
    .m-xxl-19 {
        margin: 4.75rem !important;
    }
    
    .m-xxl-20 {
        margin: 5rem !important;
    }
}

/* Bottom */

.mb-auto {
    margin-bottom: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 0.75rem !important;
}

.mb-4 {
    margin-bottom: 1rem !important;
}

.mb-5 {
    margin-bottom: 1.25rem !important;
}

.mb-6 {
    margin-bottom: 1.5rem !important;
}

.mb-7 {
    margin-bottom: 1.75rem !important;
}

.mb-8 {
    margin-bottom: 2rem !important;
}

.mb-9 {
    margin-bottom: 2.25rem !important;
}

.mb-10 {
    margin-bottom: 2.5rem !important;
}

.mb-11 {
    margin-bottom: 2.75rem !important;
}

.mb-12 {
    margin-bottom: 3rem !important;
}

.mb-13 {
    margin-bottom: 3.25rem !important;
}

.mb-14 {
    margin-bottom: 3.5rem !important;
}

.mb-15 {
    margin-bottom: 3.75rem !important;
}

.mb-16 {
    margin-bottom: 4rem !important;
}

.mb-17 {
    margin-bottom: 4.25rem !important;
}

.mb-18 {
    margin-bottom: 4.5rem !important;
}

.mb-19 {
    margin-bottom: 4.75rem !important;
}

.mb-20 {
    margin-bottom: 5rem !important;
}

@media (min-width: 576px) {
    .mb-sm-auto {
        margin-bottom: auto !important;
    }
    
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .mb-sm-3 {
        margin-bottom: 0.75rem !important;
    }
    
    .mb-sm-4 {
        margin-bottom: 1rem !important;
    }
    
    .mb-sm-5 {
        margin-bottom: 1.25rem !important;
    }
    
    .mb-sm-6 {
        margin-bottom: 1.5rem !important;
    }
    
    .mb-sm-7 {
        margin-bottom: 1.75rem !important;
    }
    
    .mb-sm-8 {
        margin-bottom: 2rem !important;
    }
    
    .mb-sm-9 {
        margin-bottom: 2.25rem !important;
    }
    
    .mb-sm-10 {
        margin-bottom: 2.5rem !important;
    }
    
    .mb-sm-11 {
        margin-bottom: 2.75rem !important;
    }
    
    .mb-sm-12 {
        margin-bottom: 3rem !important;
    }
    
    .mb-sm-13 {
        margin-bottom: 3.25rem !important;
    }
    
    .mb-sm-14 {
        margin-bottom: 3.5rem !important;
    }
    
    .mb-sm-15 {
        margin-bottom: 3.75rem !important;
    }
    
    .mb-sm-16 {
        margin-bottom: 4rem !important;
    }
    
    .mb-sm-17 {
        margin-bottom: 4.25rem !important;
    }
    
    .mb-sm-18 {
        margin-bottom: 4.5rem !important;
    }
    
    .mb-sm-19 {
        margin-bottom: 4.75rem !important;
    }
    
    .mb-sm-20 {
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 768px) {
    .mb-md-auto {
        margin-bottom: auto !important;
    }
    
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .mb-md-3 {
        margin-bottom: 0.75rem !important;
    }
    
    .mb-md-4 {
        margin-bottom: 1rem !important;
    }
    
    .mb-md-5 {
        margin-bottom: 1.25rem !important;
    }
    
    .mb-md-6 {
        margin-bottom: 1.5rem !important;
    }
    
    .mb-md-7 {
        margin-bottom: 1.75rem !important;
    }
    
    .mb-md-8 {
        margin-bottom: 2rem !important;
    }
    
    .mb-md-9 {
        margin-bottom: 2.25rem !important;
    }
    
    .mb-md-10 {
        margin-bottom: 2.5rem !important;
    }
    
    .mb-md-11 {
        margin-bottom: 2.75rem !important;
    }
    
    .mb-md-12 {
        margin-bottom: 3rem !important;
    }
    
    .mb-md-13 {
        margin-bottom: 3.25rem !important;
    }
    
    .mb-md-14 {
        margin-bottom: 3.5rem !important;
    }
    
    .mb-md-15 {
        margin-bottom: 3.75rem !important;
    }
    
    .mb-md-16 {
        margin-bottom: 4rem !important;
    }
    
    .mb-md-17 {
        margin-bottom: 4.25rem !important;
    }
    
    .mb-md-18 {
        margin-bottom: 4.5rem !important;
    }
    
    .mb-md-19 {
        margin-bottom: 4.75rem !important;
    }
    
    .mb-md-20 {
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 992px) {
    .mb-lg-auto {
        margin-bottom: auto !important;
    }
    
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .mb-lg-3 {
        margin-bottom: 0.75rem !important;
    }
    
    .mb-lg-4 {
        margin-bottom: 1rem !important;
    }
    
    .mb-lg-5 {
        margin-bottom: 1.25rem !important;
    }
    
    .mb-lg-6 {
        margin-bottom: 1.5rem !important;
    }
    
    .mb-lg-7 {
        margin-bottom: 1.75rem !important;
    }
    
    .mb-lg-8 {
        margin-bottom: 2rem !important;
    }
    
    .mb-lg-9 {
        margin-bottom: 2.25rem !important;
    }
    
    .mb-lg-10 {
        margin-bottom: 2.5rem !important;
    }
    
    .mb-lg-11 {
        margin-bottom: 2.75rem !important;
    }
    
    .mb-lg-12 {
        margin-bottom: 3rem !important;
    }
    
    .mb-lg-13 {
        margin-bottom: 3.25rem !important;
    }
    
    .mb-lg-14 {
        margin-bottom: 3.5rem !important;
    }
    
    .mb-lg-15 {
        margin-bottom: 3.75rem !important;
    }
    
    .mb-lg-16 {
        margin-bottom: 4rem !important;
    }
    
    .mb-lg-17 {
        margin-bottom: 4.25rem !important;
    }
    
    .mb-lg-18 {
        margin-bottom: 4.5rem !important;
    }
    
    .mb-lg-19 {
        margin-bottom: 4.75rem !important;
    }
    
    .mb-lg-20 {
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .mb-xl-auto {
        margin-bottom: auto !important;
    }
    
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .mb-xl-3 {
        margin-bottom: 0.75rem !important;
    }
    
    .mb-xl-4 {
        margin-bottom: 1rem !important;
    }
    
    .mb-xl-5 {
        margin-bottom: 1.25rem !important;
    }
    
    .mb-xl-6 {
        margin-bottom: 1.5rem !important;
    }
    
    .mb-xl-7 {
        margin-bottom: 1.75rem !important;
    }
    
    .mb-xl-8 {
        margin-bottom: 2rem !important;
    }
    
    .mb-xl-9 {
        margin-bottom: 2.25rem !important;
    }
    
    .mb-xl-10 {
        margin-bottom: 2.5rem !important;
    }
    
    .mb-xl-11 {
        margin-bottom: 2.75rem !important;
    }
    
    .mb-xl-12 {
        margin-bottom: 3rem !important;
    }
    
    .mb-xl-13 {
        margin-bottom: 3.25rem !important;
    }
    
    .mb-xl-14 {
        margin-bottom: 3.5rem !important;
    }
    
    .mb-xl-15 {
        margin-bottom: 3.75rem !important;
    }
    
    .mb-xl-16 {
        margin-bottom: 4rem !important;
    }
    
    .mb-xl-17 {
        margin-bottom: 4.25rem !important;
    }
    
    .mb-xl-18 {
        margin-bottom: 4.5rem !important;
    }
    
    .mb-xl-19 {
        margin-bottom: 4.75rem !important;
    }
    
    .mb-xl-20 {
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .mb-xxl-auto {
        margin-bottom: auto !important;
    }
    
    .mb-xxl-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-xxl-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .mb-xxl-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .mb-xxl-3 {
        margin-bottom: 0.75rem !important;
    }
    
    .mb-xxl-4 {
        margin-bottom: 1rem !important;
    }
    
    .mb-xxl-5 {
        margin-bottom: 1.25rem !important;
    }
    
    .mb-xxl-6 {
        margin-bottom: 1.5rem !important;
    }
    
    .mb-xxl-7 {
        margin-bottom: 1.75rem !important;
    }
    
    .mb-xxl-8 {
        margin-bottom: 2rem !important;
    }
    
    .mb-xxl-9 {
        margin-bottom: 2.25rem !important;
    }
    
    .mb-xxl-10 {
        margin-bottom: 2.5rem !important;
    }
    
    .mb-xxl-11 {
        margin-bottom: 2.75rem !important;
    }
    
    .mb-xxl-12 {
        margin-bottom: 3rem !important;
    }
    
    .mb-xxl-13 {
        margin-bottom: 3.25rem !important;
    }
    
    .mb-xxl-14 {
        margin-bottom: 3.5rem !important;
    }
    
    .mb-xxl-15 {
        margin-bottom: 3.75rem !important;
    }
    
    .mb-xxl-16 {
        margin-bottom: 4rem !important;
    }
    
    .mb-xxl-17 {
        margin-bottom: 4.25rem !important;
    }
    
    .mb-xxl-18 {
        margin-bottom: 4.5rem !important;
    }
    
    .mb-xxl-19 {
        margin-bottom: 4.75rem !important;
    }
    
    .mb-xxl-20 {
        margin-bottom: 5rem !important;
    }
}

/* Left */

.ms-auto {
    margin-left: auto !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1{
    margin-left: 0.25rem !important;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

.ms-3 {
    margin-left: 0.75rem !important;
}

.ms-4 {
    margin-left: 1rem !important;
}

.ms-5 {
    margin-left: 1.25rem !important;
}

.ms-6 {
    margin-left: 1.5rem !important;
}

.ms-7 {
    margin-left: 1.75rem !important;
}

.ms-8 {
    margin-left: 2rem !important;
}

.ms-9 {
    margin-left: 2.25rem !important;
}

.ms-10 {
    margin-left: 2.5rem !important;
}

.ms-11 {
    margin-left: 2.75rem !important;
}

.ms-12 {
    margin-left: 3rem !important;
}

.ms-13 {
    margin-left: 3.25rem !important;
}

.ms-14 {
    margin-left: 3.5rem !important;
}

.ms-15 {
    margin-left: 3.75rem !important;
}

.ms-16 {
    margin-left: 4rem !important;
}

.ms-17 {
    margin-left: 4.25rem !important;
}

.ms-18 {
    margin-left: 4.5rem !important;
}

.ms-19 {
    margin-left: 4.75rem !important;
}

.ms-20 {
    margin-left: 5rem !important;
}

@media (min-width: 576px) {
    .ms-sm-auto {
        margin-left: auto !important;
    }
    
    .ms-sm-0 {
        margin-left: 0 !important;
    }
    
    .ms-sm-1{
        margin-left: 0.25rem !important;
    }
    
    .ms-sm-2 {
        margin-left: 0.5rem !important;
    }
    
    .ms-sm-3 {
        margin-left: 0.75rem !important;
    }
    
    .ms-sm-4 {
        margin-left: 1rem !important;
    }
    
    .ms-sm-5 {
        margin-left: 1.25rem !important;
    }
    
    .ms-sm-6 {
        margin-left: 1.5rem !important;
    }
    
    .ms-sm-7 {
        margin-left: 1.75rem !important;
    }
    
    .ms-sm-8 {
        margin-left: 2rem !important;
    }
    
    .ms-sm-9 {
        margin-left: 2.25rem !important;
    }
    
    .ms-sm-10 {
        margin-left: 2.5rem !important;
    }
    
    .ms-sm-11 {
        margin-left: 2.75rem !important;
    }
    
    .ms-sm-12 {
        margin-left: 3rem !important;
    }
    
    .ms-sm-13 {
        margin-left: 3.25rem !important;
    }
    
    .ms-sm-14 {
        margin-left: 3.5rem !important;
    }
    
    .ms-sm-15 {
        margin-left: 3.75rem !important;
    }
    
    .ms-sm-16 {
        margin-left: 4rem !important;
    }
    
    .ms-sm-17 {
        margin-left: 4.25rem !important;
    }
    
    .ms-sm-18 {
        margin-left: 4.5rem !important;
    }
    
    .ms-sm-19 {
        margin-left: 4.75rem !important;
    }
    
    .ms-sm-20 {
        margin-left: 5rem !important;
    }
}

@media (min-width: 768px) {
    .ms-md-auto {
        margin-left: auto !important;
    }
    
    .ms-md-0 {
        margin-left: 0 !important;
    }
    
    .ms-md-1{
        margin-left: 0.25rem !important;
    }
    
    .ms-md-2 {
        margin-left: 0.5rem !important;
    }
    
    .ms-md-3 {
        margin-left: 0.75rem !important;
    }
    
    .ms-md-4 {
        margin-left: 1rem !important;
    }
    
    .ms-md-5 {
        margin-left: 1.25rem !important;
    }
    
    .ms-md-6 {
        margin-left: 1.5rem !important;
    }
    
    .ms-md-7 {
        margin-left: 1.75rem !important;
    }
    
    .ms-md-8 {
        margin-left: 2rem !important;
    }
    
    .ms-md-9 {
        margin-left: 2.25rem !important;
    }
    
    .ms-md-10 {
        margin-left: 2.5rem !important;
    }
    
    .ms-md-11 {
        margin-left: 2.75rem !important;
    }
    
    .ms-md-12 {
        margin-left: 3rem !important;
    }
    
    .ms-md-13 {
        margin-left: 3.25rem !important;
    }
    
    .ms-md-14 {
        margin-left: 3.5rem !important;
    }
    
    .ms-md-15 {
        margin-left: 3.75rem !important;
    }
    
    .ms-md-16 {
        margin-left: 4rem !important;
    }
    
    .ms-md-17 {
        margin-left: 4.25rem !important;
    }
    
    .ms-md-18 {
        margin-left: 4.5rem !important;
    }
    
    .ms-md-19 {
        margin-left: 4.75rem !important;
    }
    
    .ms-md-20 {
        margin-left: 5rem !important;
    }
}

@media (min-width: 992px) {
    .ms-lg-auto {
        margin-left: auto !important;
    }
    
    .ms-lg-0 {
        margin-left: 0 !important;
    }
    
    .ms-lg-1{
        margin-left: 0.25rem !important;
    }
    
    .ms-lg-2 {
        margin-left: 0.5rem !important;
    }
    
    .ms-lg-3 {
        margin-left: 0.75rem !important;
    }
    
    .ms-lg-4 {
        margin-left: 1rem !important;
    }
    
    .ms-lg-5 {
        margin-left: 1.25rem !important;
    }
    
    .ms-lg-6 {
        margin-left: 1.5rem !important;
    }
    
    .ms-lg-7 {
        margin-left: 1.75rem !important;
    }
    
    .ms-lg-8 {
        margin-left: 2rem !important;
    }
    
    .ms-lg-9 {
        margin-left: 2.25rem !important;
    }
    
    .ms-lg-10 {
        margin-left: 2.5rem !important;
    }
    
    .ms-lg-11 {
        margin-left: 2.75rem !important;
    }
    
    .ms-lg-12 {
        margin-left: 3rem !important;
    }
    
    .ms-lg-13 {
        margin-left: 3.25rem !important;
    }
    
    .ms-lg-14 {
        margin-left: 3.5rem !important;
    }
    
    .ms-lg-15 {
        margin-left: 3.75rem !important;
    }
    
    .ms-lg-16 {
        margin-left: 4rem !important;
    }
    
    .ms-lg-17 {
        margin-left: 4.25rem !important;
    }
    
    .ms-lg-18 {
        margin-left: 4.5rem !important;
    }
    
    .ms-lg-19 {
        margin-left: 4.75rem !important;
    }
    
    .ms-lg-20 {
        margin-left: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .ms-xl-auto {
        margin-left: auto !important;
    }
    
    .ms-xl-0 {
        margin-left: 0 !important;
    }
    
    .ms-xl-1{
        margin-left: 0.25rem !important;
    }
    
    .ms-xl-2 {
        margin-left: 0.5rem !important;
    }
    
    .ms-xl-3 {
        margin-left: 0.75rem !important;
    }
    
    .ms-xl-4 {
        margin-left: 1rem !important;
    }
    
    .ms-xl-5 {
        margin-left: 1.25rem !important;
    }
    
    .ms-xl-6 {
        margin-left: 1.5rem !important;
    }
    
    .ms-xl-7 {
        margin-left: 1.75rem !important;
    }
    
    .ms-xl-8 {
        margin-left: 2rem !important;
    }
    
    .ms-xl-9 {
        margin-left: 2.25rem !important;
    }
    
    .ms-xl-10 {
        margin-left: 2.5rem !important;
    }
    
    .ms-xl-11 {
        margin-left: 2.75rem !important;
    }
    
    .ms-xl-12 {
        margin-left: 3rem !important;
    }
    
    .ms-xl-13 {
        margin-left: 3.25rem !important;
    }
    
    .ms-xl-14 {
        margin-left: 3.5rem !important;
    }
    
    .ms-xl-15 {
        margin-left: 3.75rem !important;
    }
    
    .ms-xl-16 {
        margin-left: 4rem !important;
    }
    
    .ms-xl-17 {
        margin-left: 4.25rem !important;
    }
    
    .ms-xl-18 {
        margin-left: 4.5rem !important;
    }
    
    .ms-xl-19 {
        margin-left: 4.75rem !important;
    }
    
    .ms-xl-20 {
        margin-left: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .ms-xxl-auto {
        margin-left: auto !important;
    }
    
    .ms-xxl-0 {
        margin-left: 0 !important;
    }
    
    .ms-xxl-1{
        margin-left: 0.25rem !important;
    }
    
    .ms-xxl-2 {
        margin-left: 0.5rem !important;
    }
    
    .ms-xxl-3 {
        margin-left: 0.75rem !important;
    }
    
    .ms-xxl-4 {
        margin-left: 1rem !important;
    }
    
    .ms-xxl-5 {
        margin-left: 1.25rem !important;
    }
    
    .ms-xxl-6 {
        margin-left: 1.5rem !important;
    }
    
    .ms-xxl-7 {
        margin-left: 1.75rem !important;
    }
    
    .ms-xxl-8 {
        margin-left: 2rem !important;
    }
    
    .ms-xxl-9 {
        margin-left: 2.25rem !important;
    }
    
    .ms-xxl-10 {
        margin-left: 2.5rem !important;
    }
    
    .ms-xxl-11 {
        margin-left: 2.75rem !important;
    }
    
    .ms-xxl-12 {
        margin-left: 3rem !important;
    }
    
    .ms-xxl-13 {
        margin-left: 3.25rem !important;
    }
    
    .ms-xxl-14 {
        margin-left: 3.5rem !important;
    }
    
    .ms-xxl-15 {
        margin-left: 3.75rem !important;
    }
    
    .ms-xxl-16 {
        margin-left: 4rem !important;
    }
    
    .ms-xxl-17 {
        margin-left: 4.25rem !important;
    }
    
    .ms-xxl-18 {
        margin-left: 4.5rem !important;
    }
    
    .ms-xxl-19 {
        margin-left: 4.75rem !important;
    }
    
    .ms-xxl-20 {
        margin-left: 5rem !important;
    }
}

/* Right */

.me-auto {
    margin-right: auto !important;
}

.me-0 {
    margin-right: 0 !important;
}

.me-1{
    margin-right: 0.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.me-3 {
    margin-right: 0.75rem !important;
}

.me-4 {
    margin-right: 1rem !important;
}

.me-5 {
    margin-right: 1.25rem !important;
}

.me-6 {
    margin-right: 1.5rem !important;
}

.me-7 {
    margin-right: 1.75rem !important;
}

.me-8 {
    margin-right: 2rem !important;
}

.me-9 {
    margin-right: 2.25rem !important;
}

.me-10 {
    margin-right: 2.5rem !important;
}

.me-11 {
    margin-right: 2.75rem !important;
}

.me-12 {
    margin-right: 3rem !important;
}

.me-13 {
    margin-right: 3.25rem !important;
}

.me-14 {
    margin-right: 3.5rem !important;
}

.me-15 {
    margin-right: 3.75rem !important;
}

.me-16 {
    margin-right: 4rem !important;
}

.me-17 {
    margin-right: 4.25rem !important;
}

.me-18 {
    margin-right: 4.5rem !important;
}

.me-19 {
    margin-right: 4.75rem !important;
}

.me-20 {
    margin-right: 5rem !important;
}

@media (min-width: 576px) {
    .me-sm-auto {
        margin-right: auto !important;
    }
    
    .me-sm-0 {
        margin-right: 0 !important;
    }
    
    .me-sm-1{
        margin-right: 0.25rem !important;
    }
    
    .me-sm-2 {
        margin-right: 0.5rem !important;
    }
    
    .me-sm-3 {
        margin-right: 0.75rem !important;
    }
    
    .me-sm-4 {
        margin-right: 1rem !important;
    }
    
    .me-sm-5 {
        margin-right: 1.25rem !important;
    }
    
    .me-sm-6 {
        margin-right: 1.5rem !important;
    }
    
    .me-sm-7 {
        margin-right: 1.75rem !important;
    }
    
    .me-sm-8 {
        margin-right: 2rem !important;
    }
    
    .me-sm-9 {
        margin-right: 2.25rem !important;
    }
    
    .me-sm-10 {
        margin-right: 2.5rem !important;
    }
    
    .me-sm-11 {
        margin-right: 2.75rem !important;
    }
    
    .me-sm-12 {
        margin-right: 3rem !important;
    }
    
    .me-sm-13 {
        margin-right: 3.25rem !important;
    }
    
    .me-sm-14 {
        margin-right: 3.5rem !important;
    }
    
    .me-sm-15 {
        margin-right: 3.75rem !important;
    }
    
    .me-sm-16 {
        margin-right: 4rem !important;
    }
    
    .me-sm-17 {
        margin-right: 4.25rem !important;
    }
    
    .me-sm-18 {
        margin-right: 4.5rem !important;
    }
    
    .me-sm-19 {
        margin-right: 4.75rem !important;
    }
    
    .me-sm-20 {
        margin-right: 5rem !important;
    }
}

@media (min-width: 768px) {
    .me-md-auto {
        margin-right: auto !important;
    }
    
    .me-md-0 {
        margin-right: 0 !important;
    }
    
    .me-md-1{
        margin-right: 0.25rem !important;
    }
    
    .me-md-2 {
        margin-right: 0.5rem !important;
    }
    
    .me-md-3 {
        margin-right: 0.75rem !important;
    }
    
    .me-md-4 {
        margin-right: 1rem !important;
    }
    
    .me-md-5 {
        margin-right: 1.25rem !important;
    }
    
    .me-md-6 {
        margin-right: 1.5rem !important;
    }
    
    .me-md-7 {
        margin-right: 1.75rem !important;
    }
    
    .me-md-8 {
        margin-right: 2rem !important;
    }
    
    .me-md-9 {
        margin-right: 2.25rem !important;
    }
    
    .me-md-10 {
        margin-right: 2.5rem !important;
    }
    
    .me-md-11 {
        margin-right: 2.75rem !important;
    }
    
    .me-md-12 {
        margin-right: 3rem !important;
    }
    
    .me-md-13 {
        margin-right: 3.25rem !important;
    }
    
    .me-md-14 {
        margin-right: 3.5rem !important;
    }
    
    .me-md-15 {
        margin-right: 3.75rem !important;
    }
    
    .me-md-16 {
        margin-right: 4rem !important;
    }
    
    .me-md-17 {
        margin-right: 4.25rem !important;
    }
    
    .me-md-18 {
        margin-right: 4.5rem !important;
    }
    
    .me-md-19 {
        margin-right: 4.75rem !important;
    }
    
    .me-md-20 {
        margin-right: 5rem !important;
    }
}

@media (min-width: 992px) {
    .me-lg-auto {
        margin-right: auto !important;
    }
    
    .me-lg-0 {
        margin-right: 0 !important;
    }
    
    .me-lg-1{
        margin-right: 0.25rem !important;
    }
    
    .me-lg-2 {
        margin-right: 0.5rem !important;
    }
    
    .me-lg-3 {
        margin-right: 0.75rem !important;
    }
    
    .me-lg-4 {
        margin-right: 1rem !important;
    }
    
    .me-lg-5 {
        margin-right: 1.25rem !important;
    }
    
    .me-lg-6 {
        margin-right: 1.5rem !important;
    }
    
    .me-lg-7 {
        margin-right: 1.75rem !important;
    }
    
    .me-lg-8 {
        margin-right: 2rem !important;
    }
    
    .me-lg-9 {
        margin-right: 2.25rem !important;
    }
    
    .me-lg-10 {
        margin-right: 2.5rem !important;
    }
    
    .me-lg-11 {
        margin-right: 2.75rem !important;
    }
    
    .me-lg-12 {
        margin-right: 3rem !important;
    }
    
    .me-lg-13 {
        margin-right: 3.25rem !important;
    }
    
    .me-lg-14 {
        margin-right: 3.5rem !important;
    }
    
    .me-lg-15 {
        margin-right: 3.75rem !important;
    }
    
    .me-lg-16 {
        margin-right: 4rem !important;
    }
    
    .me-lg-17 {
        margin-right: 4.25rem !important;
    }
    
    .me-lg-18 {
        margin-right: 4.5rem !important;
    }
    
    .me-lg-19 {
        margin-right: 4.75rem !important;
    }
    
    .me-lg-20 {
        margin-right: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .me-xl-lg-auto {
        margin-right: auto !important;
    }
    
    .me-xl-lg-0 {
        margin-right: 0 !important;
    }
    
    .me-xl-lg-1{
        margin-right: 0.25rem !important;
    }
    
    .me-xl-lg-2 {
        margin-right: 0.5rem !important;
    }
    
    .me-xl-lg-3 {
        margin-right: 0.75rem !important;
    }
    
    .me-xl-lg-4 {
        margin-right: 1rem !important;
    }
    
    .me-xl-lg-5 {
        margin-right: 1.25rem !important;
    }
    
    .me-xl-lg-6 {
        margin-right: 1.5rem !important;
    }
    
    .me-xl-lg-7 {
        margin-right: 1.75rem !important;
    }
    
    .me-xl-lg-8 {
        margin-right: 2rem !important;
    }
    
    .me-xl-lg-9 {
        margin-right: 2.25rem !important;
    }
    
    .me-xl-lg-10 {
        margin-right: 2.5rem !important;
    }
    
    .me-xl-lg-11 {
        margin-right: 2.75rem !important;
    }
    
    .me-xl-lg-12 {
        margin-right: 3rem !important;
    }
    
    .me-xl-lg-13 {
        margin-right: 3.25rem !important;
    }
    
    .me-xl-lg-14 {
        margin-right: 3.5rem !important;
    }
    
    .me-xl-lg-15 {
        margin-right: 3.75rem !important;
    }
    
    .me-xl-lg-16 {
        margin-right: 4rem !important;
    }
    
    .me-xl-lg-17 {
        margin-right: 4.25rem !important;
    }
    
    .me-xl-lg-18 {
        margin-right: 4.5rem !important;
    }
    
    .me-xl-lg-19 {
        margin-right: 4.75rem !important;
    }
    
    .me-xl-lg-20 {
        margin-right: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .me-xxl-lg-auto {
        margin-right: auto !important;
    }
    
    .me-xxl-lg-0 {
        margin-right: 0 !important;
    }
    
    .me-xxl-lg-1{
        margin-right: 0.25rem !important;
    }
    
    .me-xxl-lg-2 {
        margin-right: 0.5rem !important;
    }
    
    .me-xxl-lg-3 {
        margin-right: 0.75rem !important;
    }
    
    .me-xxl-lg-4 {
        margin-right: 1rem !important;
    }
    
    .me-xxl-lg-5 {
        margin-right: 1.25rem !important;
    }
    
    .me-xxl-lg-6 {
        margin-right: 1.5rem !important;
    }
    
    .me-xxl-lg-7 {
        margin-right: 1.75rem !important;
    }
    
    .me-xxl-lg-8 {
        margin-right: 2rem !important;
    }
    
    .me-xxl-lg-9 {
        margin-right: 2.25rem !important;
    }
    
    .me-xxl-lg-10 {
        margin-right: 2.5rem !important;
    }
    
    .me-xxl-lg-11 {
        margin-right: 2.75rem !important;
    }
    
    .me-xxl-lg-12 {
        margin-right: 3rem !important;
    }
    
    .me-xxl-lg-13 {
        margin-right: 3.25rem !important;
    }
    
    .me-xxl-lg-14 {
        margin-right: 3.5rem !important;
    }
    
    .me-xxl-lg-15 {
        margin-right: 3.75rem !important;
    }
    
    .me-xxl-lg-16 {
        margin-right: 4rem !important;
    }
    
    .me-xxl-lg-17 {
        margin-right: 4.25rem !important;
    }
    
    .me-xxl-lg-18 {
        margin-right: 4.5rem !important;
    }
    
    .me-xxl-lg-19 {
        margin-right: 4.75rem !important;
    }
    
    .me-xxl-lg-20 {
        margin-right: 5rem !important;
    }
}

/* Top */

.mt-auto {
    margin-top: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 0.75rem !important;
}

.mt-4 {
    margin-top: 1rem !important;
}

.mt-5 {
    margin-top: 1.25rem !important;
}

.mt-6 {
    margin-top: 1.5rem !important;
}

.mt-7 {
    margin-top: 1.75rem !important;
}

.mt-8 {
    margin-top: 2rem !important;
}

.mt-9 {
    margin-top: 2.25rem !important;
}

.mt-10 {
    margin-top: 2.5rem !important;
}

.mt-11 {
    margin-top: 2.75rem !important;
}

.mt-12 {
    margin-top: 3rem !important;
}

.mt-13 {
    margin-top: 3.25rem !important;
}

.mt-14 {
    margin-top: 3.5rem !important;
}

.mt-15 {
    margin-top: 3.75rem !important;
}

.mt-16 {
    margin-top: 4rem !important;
}

.mt-17 {
    margin-top: 4.25rem !important;
}

.mt-18 {
    margin-top: 4.5rem !important;
}

.mt-19 {
    margin-top: 4.75rem !important;
}

.mt-20 {
    margin-top: 5rem !important;
}

@media (min-width: 576px) {
    .mt-sm-auto {
        margin-top: auto !important;
    }
    
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }
    
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    
    .mt-sm-3 {
        margin-top: 0.75rem !important;
    }
    
    .mt-sm-4 {
        margin-top: 1rem !important;
    }
    
    .mt-sm-5 {
        margin-top: 1.25rem !important;
    }
    
    .mt-sm-6 {
        margin-top: 1.5rem !important;
    }
    
    .mt-sm-7 {
        margin-top: 1.75rem !important;
    }
    
    .mt-sm-8 {
        margin-top: 2rem !important;
    }
    
    .mt-sm-9 {
        margin-top: 2.25rem !important;
    }
    
    .mt-sm-10 {
        margin-top: 2.5rem !important;
    }
    
    .mt-sm-11 {
        margin-top: 2.75rem !important;
    }
    
    .mt-sm-12 {
        margin-top: 3rem !important;
    }
    
    .mt-sm-13 {
        margin-top: 3.25rem !important;
    }
    
    .mt-sm-14 {
        margin-top: 3.5rem !important;
    }
    
    .mt-sm-15 {
        margin-top: 3.75rem !important;
    }
    
    .mt-sm-16 {
        margin-top: 4rem !important;
    }
    
    .mt-sm-17 {
        margin-top: 4.25rem !important;
    }
    
    .mt-sm-18 {
        margin-top: 4.5rem !important;
    }
    
    .mt-sm-19 {
        margin-top: 4.75rem !important;
    }
    
    .mt-sm-20 {
        margin-top: 5rem !important;
    }
}

@media (min-width: 768px) {
    .mt-md-auto {
        margin-top: auto !important;
    }
    
    .mt-md-0 {
        margin-top: 0 !important;
    }
    
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }
    
    .mt-md-2 {
        margin-top: 0.5rem !important;
    }
    
    .mt-md-3 {
        margin-top: 0.75rem !important;
    }
    
    .mt-md-4 {
        margin-top: 1rem !important;
    }
    
    .mt-md-5 {
        margin-top: 1.25rem !important;
    }
    
    .mt-md-6 {
        margin-top: 1.5rem !important;
    }
    
    .mt-md-7 {
        margin-top: 1.75rem !important;
    }
    
    .mt-md-8 {
        margin-top: 2rem !important;
    }
    
    .mt-md-9 {
        margin-top: 2.25rem !important;
    }
    
    .mt-md-10 {
        margin-top: 2.5rem !important;
    }
    
    .mt-md-11 {
        margin-top: 2.75rem !important;
    }
    
    .mt-md-12 {
        margin-top: 3rem !important;
    }
    
    .mt-md-13 {
        margin-top: 3.25rem !important;
    }
    
    .mt-md-14 {
        margin-top: 3.5rem !important;
    }
    
    .mt-md-15 {
        margin-top: 3.75rem !important;
    }
    
    .mt-md-16 {
        margin-top: 4rem !important;
    }
    
    .mt-md-17 {
        margin-top: 4.25rem !important;
    }
    
    .mt-md-18 {
        margin-top: 4.5rem !important;
    }
    
    .mt-md-19 {
        margin-top: 4.75rem !important;
    }
    
    .mt-md-20 {
        margin-top: 5rem !important;
    }
}

@media (min-width: 992px) {
    .mt-lg-auto {
        margin-top: auto !important;
    }
    
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }
    
    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }
    
    .mt-lg-3 {
        margin-top: 0.75rem !important;
    }
    
    .mt-lg-4 {
        margin-top: 1rem !important;
    }
    
    .mt-lg-5 {
        margin-top: 1.25rem !important;
    }
    
    .mt-lg-6 {
        margin-top: 1.5rem !important;
    }
    
    .mt-lg-7 {
        margin-top: 1.75rem !important;
    }
    
    .mt-lg-8 {
        margin-top: 2rem !important;
    }
    
    .mt-lg-9 {
        margin-top: 2.25rem !important;
    }
    
    .mt-lg-10 {
        margin-top: 2.5rem !important;
    }
    
    .mt-lg-11 {
        margin-top: 2.75rem !important;
    }
    
    .mt-lg-12 {
        margin-top: 3rem !important;
    }
    
    .mt-lg-13 {
        margin-top: 3.25rem !important;
    }
    
    .mt-lg-14 {
        margin-top: 3.5rem !important;
    }
    
    .mt-lg-15 {
        margin-top: 3.75rem !important;
    }
    
    .mt-lg-16 {
        margin-top: 4rem !important;
    }
    
    .mt-lg-17 {
        margin-top: 4.25rem !important;
    }
    
    .mt-lg-18 {
        margin-top: 4.5rem !important;
    }
    
    .mt-lg-19 {
        margin-top: 4.75rem !important;
    }
    
    .mt-lg-20 {
        margin-top: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .mt-xl-auto {
        margin-top: auto !important;
    }
    
    .mt-xl-0 {
        margin-top: 0 !important;
    }
    
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }
    
    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }
    
    .mt-xl-3 {
        margin-top: 0.75rem !important;
    }
    
    .mt-xl-4 {
        margin-top: 1rem !important;
    }
    
    .mt-xl-5 {
        margin-top: 1.25rem !important;
    }
    
    .mt-xl-6 {
        margin-top: 1.5rem !important;
    }
    
    .mt-xl-7 {
        margin-top: 1.75rem !important;
    }
    
    .mt-xl-8 {
        margin-top: 2rem !important;
    }
    
    .mt-xl-9 {
        margin-top: 2.25rem !important;
    }
    
    .mt-xl-10 {
        margin-top: 2.5rem !important;
    }
    
    .mt-xl-11 {
        margin-top: 2.75rem !important;
    }
    
    .mt-xl-12 {
        margin-top: 3rem !important;
    }
    
    .mt-xl-13 {
        margin-top: 3.25rem !important;
    }
    
    .mt-xl-14 {
        margin-top: 3.5rem !important;
    }
    
    .mt-xl-15 {
        margin-top: 3.75rem !important;
    }
    
    .mt-xl-16 {
        margin-top: 4rem !important;
    }
    
    .mt-xl-17 {
        margin-top: 4.25rem !important;
    }
    
    .mt-xl-18 {
        margin-top: 4.5rem !important;
    }
    
    .mt-xl-19 {
        margin-top: 4.75rem !important;
    }
    
    .mt-xl-20 {
        margin-top: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .mt-xxl-auto {
        margin-top: auto !important;
    }
    
    .mt-xxl-0 {
        margin-top: 0 !important;
    }
    
    .mt-xxl-1 {
        margin-top: 0.25rem !important;
    }
    
    .mt-xxl-2 {
        margin-top: 0.5rem !important;
    }
    
    .mt-xxl-3 {
        margin-top: 0.75rem !important;
    }
    
    .mt-xxl-4 {
        margin-top: 1rem !important;
    }
    
    .mt-xxl-5 {
        margin-top: 1.25rem !important;
    }
    
    .mt-xxl-6 {
        margin-top: 1.5rem !important;
    }
    
    .mt-xxl-7 {
        margin-top: 1.75rem !important;
    }
    
    .mt-xxl-8 {
        margin-top: 2rem !important;
    }
    
    .mt-xxl-9 {
        margin-top: 2.25rem !important;
    }
    
    .mt-xxl-10 {
        margin-top: 2.5rem !important;
    }
    
    .mt-xxl-11 {
        margin-top: 2.75rem !important;
    }
    
    .mt-xxl-12 {
        margin-top: 3rem !important;
    }
    
    .mt-xxl-13 {
        margin-top: 3.25rem !important;
    }
    
    .mt-xxl-14 {
        margin-top: 3.5rem !important;
    }
    
    .mt-xxl-15 {
        margin-top: 3.75rem !important;
    }
    
    .mt-xxl-16 {
        margin-top: 4rem !important;
    }
    
    .mt-xxl-17 {
        margin-top: 4.25rem !important;
    }
    
    .mt-xxl-18 {
        margin-top: 4.5rem !important;
    }
    
    .mt-xxl-19 {
        margin-top: 4.75rem !important;
    }
    
    .mt-xxl-20 {
        margin-top: 5rem !important;
    }
}

/* Left and Right */

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}

.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
}

.mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.mx-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
}

.mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.mx-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
}

.mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
}

.mx-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
}

.mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.mx-13 {
    margin-left: 3.25rem !important;
    margin-right: 3.25rem !important;
}

.mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
}

.mx-15 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
}

.mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
}

.mx-17 {
    margin-left: 4.25rem !important;
    margin-right: 4.25rem !important;
}

.mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
}

.mx-19 {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
}

.mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
}

@media (min-width: 576px) {
    .mx-sm-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .mx-sm-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }
    
    .mx-sm-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    
    .mx-sm-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }
    
    .mx-sm-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    
    .mx-sm-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    
    .mx-sm-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }
    
    .mx-sm-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }
    
    .mx-sm-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    
    .mx-sm-9 {
        margin-left: 2.25rem !important;
        margin-right: 2.25rem !important;
    }
    
    .mx-sm-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }
    
    .mx-sm-11 {
        margin-left: 2.75rem !important;
        margin-right: 2.75rem !important;
    }
    
    .mx-sm-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
    
    .mx-sm-13 {
        margin-left: 3.25rem !important;
        margin-right: 3.25rem !important;
    }
    
    .mx-sm-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }
    
    .mx-sm-15 {
        margin-left: 3.75rem !important;
        margin-right: 3.75rem !important;
    }
    
    .mx-sm-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
    
    .mx-sm-17 {
        margin-left: 4.25rem !important;
        margin-right: 4.25rem !important;
    }
    
    .mx-sm-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }
    
    .mx-sm-19 {
        margin-left: 4.75rem !important;
        margin-right: 4.75rem !important;
    }
    
    .mx-sm-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }
}

@media (min-width: 768px) {
    .mx-md-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .mx-md-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }
    
    .mx-md-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    
    .mx-md-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }
    
    .mx-md-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    
    .mx-md-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    
    .mx-md-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }
    
    .mx-md-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }
    
    .mx-md-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    
    .mx-md-9 {
        margin-left: 2.25rem !important;
        margin-right: 2.25rem !important;
    }
    
    .mx-md-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }
    
    .mx-md-11 {
        margin-left: 2.75rem !important;
        margin-right: 2.75rem !important;
    }
    
    .mx-md-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
    
    .mx-md-13 {
        margin-left: 3.25rem !important;
        margin-right: 3.25rem !important;
    }
    
    .mx-md-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }
    
    .mx-md-15 {
        margin-left: 3.75rem !important;
        margin-right: 3.75rem !important;
    }
    
    .mx-md-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
    
    .mx-md-17 {
        margin-left: 4.25rem !important;
        margin-right: 4.25rem !important;
    }
    
    .mx-md-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }
    
    .mx-md-19 {
        margin-left: 4.75rem !important;
        margin-right: 4.75rem !important;
    }
    
    .mx-md-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }
}

@media (min-width: 992px) {
    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .mx-lg-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }
    
    .mx-lg-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    
    .mx-lg-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }
    
    .mx-lg-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    
    .mx-lg-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    
    .mx-lg-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }
    
    .mx-lg-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }
    
    .mx-lg-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    
    .mx-lg-9 {
        margin-left: 2.25rem !important;
        margin-right: 2.25rem !important;
    }
    
    .mx-lg-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }
    
    .mx-lg-11 {
        margin-left: 2.75rem !important;
        margin-right: 2.75rem !important;
    }
    
    .mx-lg-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
    
    .mx-lg-13 {
        margin-left: 3.25rem !important;
        margin-right: 3.25rem !important;
    }
    
    .mx-lg-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }
    
    .mx-lg-15 {
        margin-left: 3.75rem !important;
        margin-right: 3.75rem !important;
    }
    
    .mx-lg-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
    
    .mx-lg-17 {
        margin-left: 4.25rem !important;
        margin-right: 4.25rem !important;
    }
    
    .mx-lg-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }
    
    .mx-lg-19 {
        margin-left: 4.75rem !important;
        margin-right: 4.75rem !important;
    }
    
    .mx-lg-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .mx-xl-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .mx-xl-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }
    
    .mx-xl-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    
    .mx-xl-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }
    
    .mx-xl-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    
    .mx-xl-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    
    .mx-xl-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }
    
    .mx-xl-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }
    
    .mx-xl-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    
    .mx-xl-9 {
        margin-left: 2.25rem !important;
        margin-right: 2.25rem !important;
    }
    
    .mx-xl-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }
    
    .mx-xl-11 {
        margin-left: 2.75rem !important;
        margin-right: 2.75rem !important;
    }
    
    .mx-xl-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
    
    .mx-xl-13 {
        margin-left: 3.25rem !important;
        margin-right: 3.25rem !important;
    }
    
    .mx-xl-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }
    
    .mx-xl-15 {
        margin-left: 3.75rem !important;
        margin-right: 3.75rem !important;
    }
    
    .mx-xl-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
    
    .mx-xl-17 {
        margin-left: 4.25rem !important;
        margin-right: 4.25rem !important;
    }
    
    .mx-xl-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }
    
    .mx-xl-19 {
        margin-left: 4.75rem !important;
        margin-right: 4.75rem !important;
    }
    
    .mx-xl-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .mx-xxl-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    .mx-xxl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .mx-xxl-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }
    
    .mx-xxl-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    
    .mx-xxl-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }
    
    .mx-xxl-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    
    .mx-xxl-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }
    
    .mx-xxl-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }
    
    .mx-xxl-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }
    
    .mx-xxl-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    
    .mx-xxl-9 {
        margin-left: 2.25rem !important;
        margin-right: 2.25rem !important;
    }
    
    .mx-xxl-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }
    
    .mx-xxl-11 {
        margin-left: 2.75rem !important;
        margin-right: 2.75rem !important;
    }
    
    .mx-xxl-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
    
    .mx-xxl-13 {
        margin-left: 3.25rem !important;
        margin-right: 3.25rem !important;
    }
    
    .mx-xxl-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }
    
    .mx-xxl-15 {
        margin-left: 3.75rem !important;
        margin-right: 3.75rem !important;
    }
    
    .mx-xxl-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
    
    .mx-xxl-17 {
        margin-left: 4.25rem !important;
        margin-right: 4.25rem !important;
    }
    
    .mx-xxl-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }
    
    .mx-xxl-19 {
        margin-left: 4.75rem !important;
        margin-right: 4.75rem !important;
    }
    
    .mx-xxl-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }
}

/* Top and Bottom */

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}

.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
}

.my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
}

.my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.my-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
}

.my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
}

.my-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
}

.my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
}

.my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
}

.my-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
}

.my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.my-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
}

.my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
}

.my-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
}

.my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

@media (min-width: 576px) {
    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    
    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    
    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    .my-sm-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    
    .my-sm-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    
    .my-sm-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }
    
    .my-sm-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    
    .my-sm-7 {
        margin-top: 1.75rem !important;
        margin-bottom: 1.75rem !important;
    }
    
    .my-sm-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    
    .my-sm-9 {
        margin-top: 2.25rem !important;
        margin-bottom: 2.25rem !important;
    }
    
    .my-sm-10 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    
    .my-sm-11 {
        margin-top: 2.75rem !important;
        margin-bottom: 2.75rem !important;
    }
    
    .my-sm-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    
    .my-sm-13 {
        margin-top: 3.25rem !important;
        margin-bottom: 3.25rem !important;
    }
    
    .my-sm-14 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important;
    }
    
    .my-sm-15 {
        margin-top: 3.75rem !important;
        margin-bottom: 3.75rem !important;
    }
    
    .my-sm-16 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    
    .my-sm-17 {
        margin-top: 4.25rem !important;
        margin-bottom: 4.25rem !important;
    }
    
    .my-sm-18 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    
    .my-sm-19 {
        margin-top: 4.75rem !important;
        margin-bottom: 4.75rem !important;
    }
    
    .my-sm-20 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 768px) {
    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    
    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    
    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    .my-md-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    
    .my-md-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    
    .my-md-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }
    
    .my-md-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    
    .my-md-7 {
        margin-top: 1.75rem !important;
        margin-bottom: 1.75rem !important;
    }
    
    .my-md-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    
    .my-md-9 {
        margin-top: 2.25rem !important;
        margin-bottom: 2.25rem !important;
    }
    
    .my-md-10 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    
    .my-md-11 {
        margin-top: 2.75rem !important;
        margin-bottom: 2.75rem !important;
    }
    
    .my-md-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    
    .my-md-13 {
        margin-top: 3.25rem !important;
        margin-bottom: 3.25rem !important;
    }
    
    .my-md-14 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important;
    }
    
    .my-md-15 {
        margin-top: 3.75rem !important;
        margin-bottom: 3.75rem !important;
    }
    
    .my-md-16 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    
    .my-md-17 {
        margin-top: 4.25rem !important;
        margin-bottom: 4.25rem !important;
    }
    
    .my-md-18 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    
    .my-md-19 {
        margin-top: 4.75rem !important;
        margin-bottom: 4.75rem !important;
    }
    
    .my-md-20 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 992px) {
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    
    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    .my-lg-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    
    .my-lg-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    
    .my-lg-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }
    
    .my-lg-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    
    .my-lg-7 {
        margin-top: 1.75rem !important;
        margin-bottom: 1.75rem !important;
    }
    
    .my-lg-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    
    .my-lg-9 {
        margin-top: 2.25rem !important;
        margin-bottom: 2.25rem !important;
    }
    
    .my-lg-10 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    
    .my-lg-11 {
        margin-top: 2.75rem !important;
        margin-bottom: 2.75rem !important;
    }
    
    .my-lg-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    
    .my-lg-13 {
        margin-top: 3.25rem !important;
        margin-bottom: 3.25rem !important;
    }
    
    .my-lg-14 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important;
    }
    
    .my-lg-15 {
        margin-top: 3.75rem !important;
        margin-bottom: 3.75rem !important;
    }
    
    .my-lg-16 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    
    .my-lg-17 {
        margin-top: 4.25rem !important;
        margin-bottom: 4.25rem !important;
    }
    
    .my-lg-18 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    
    .my-lg-19 {
        margin-top: 4.75rem !important;
        margin-bottom: 4.75rem !important;
    }
    
    .my-lg-20 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    
    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    .my-xl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    
    .my-xl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    .my-xl-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    
    .my-xl-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    
    .my-xl-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }
    
    .my-xl-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    
    .my-xl-7 {
        margin-top: 1.75rem !important;
        margin-bottom: 1.75rem !important;
    }
    
    .my-xl-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    
    .my-xl-9 {
        margin-top: 2.25rem !important;
        margin-bottom: 2.25rem !important;
    }
    
    .my-xl-10 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    
    .my-xl-11 {
        margin-top: 2.75rem !important;
        margin-bottom: 2.75rem !important;
    }
    
    .my-xl-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    
    .my-xl-13 {
        margin-top: 3.25rem !important;
        margin-bottom: 3.25rem !important;
    }
    
    .my-xl-14 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important;
    }
    
    .my-xl-15 {
        margin-top: 3.75rem !important;
        margin-bottom: 3.75rem !important;
    }
    
    .my-xl-16 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    
    .my-xl-17 {
        margin-top: 4.25rem !important;
        margin-bottom: 4.25rem !important;
    }
    
    .my-xl-18 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    
    .my-xl-19 {
        margin-top: 4.75rem !important;
        margin-bottom: 4.75rem !important;
    }
    
    .my-xl-20 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
}

@media (min-width: 1400px) {
    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    
    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    .my-xxl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    
    .my-xxl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    .my-xxl-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    
    .my-xxl-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    
    .my-xxl-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }
    
    .my-xxl-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    
    .my-xxl-7 {
        margin-top: 1.75rem !important;
        margin-bottom: 1.75rem !important;
    }
    
    .my-xxl-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    
    .my-xxl-9 {
        margin-top: 2.25rem !important;
        margin-bottom: 2.25rem !important;
    }
    
    .my-xxl-10 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    
    .my-xxl-11 {
        margin-top: 2.75rem !important;
        margin-bottom: 2.75rem !important;
    }
    
    .my-xxl-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    
    .my-xxl-13 {
        margin-top: 3.25rem !important;
        margin-bottom: 3.25rem !important;
    }
    
    .my-xxl-14 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important;
    }
    
    .my-xxl-15 {
        margin-top: 3.75rem !important;
        margin-bottom: 3.75rem !important;
    }
    
    .my-xxl-16 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    
    .my-xxl-17 {
        margin-top: 4.25rem !important;
        margin-bottom: 4.25rem !important;
    }
    
    .my-xxl-18 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }
    
    .my-xxl-19 {
        margin-top: 4.75rem !important;
        margin-bottom: 4.75rem !important;
    }
    
    .my-xxl-20 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
}
