@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Quicksand:wght@300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* */

body {
    --title-color: #222222;
    --title-color-contrast: #bcbcbc;
    --content-color: #404040;
    --content-color-contrast: #acacac;
    
    font-family: "Mulish","sans-serif";
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0% 50% !important;
    background-attachment: fixed !important;

    background-image: url('./../../public/media/images/backgrounds/bg-light.jpg');
}

/* background-image: radial-gradient(at center center, #ffffff3D 0%, #ffffffC9 100%); */
body > .background-overlay {
    background-color: #fff;
    opacity: 0.9;
}

/* */

[data-theme="dark"] body {
    --title-color: #bcbcbc;
    --title-color-contrast: #222222;
    --content-color: #acacac;
    --content-color-contrast: #404040;

    background-image: url('./../../public/media/images/backgrounds/bg-dark.jpg');
}

[data-theme="dark"] body > .background-overlay {
    background-color: #000;
    opacity: 0.8;
}

/*  */

.logo-wrapper {
    margin-right: 52px;
}

@media (min-width: 992px) {
    .logo-wrapper {
        margin-right: 0;
    }
}

ion-header .logo-wrapper .logo-image {
    width: calc(100px + (130 - 100) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
}

/* */

.fg-ion-color-primary {
    color: var(--ion-color-primary);
}

.bg-ion-color-primary {
    background-color: var(--ion-color-primary);
}

.title-color {
    color: var(--title-color);
}

.content-color {
    color: var(--content-color);
}

/* */

[data-theme="light"] .theme-dark-show {
    display: none;
}

[data-theme="dark"] .theme-light-show {
    display: none;
}

/* */

.card {
    box-shadow: 0 1px 8px rgb(0 0 0 / 30%), 0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 10%) !important;
}

.card,
.card.ion-activatable::part(native) {
    border-radius: 1rem;
}

.card.ion-activatable::part(native) {
    background: transparent !important;
}

/* */

.font-xxxl {
    font-size: calc(24px + (26 - 24) * ((100vw - 320px) / (1920 - 320))) !important;
}

.font-xxl {
    font-size: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320))) !important;
}

.font-xl {
    font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320))) !important;
}

.font-lg {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320))) !important;
}

.font-md {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
}

.font-sm {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
}

.text-center {
    text-align: center;    
}

/* */

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0.63;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.background-transparent {
    background: transparent;
}

/* */

.translucent-style {
    background-clip: padding-box;
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
}

[data-theme="dark"] .translucent-style {
    background: hsla(0, 0%, 70%, 0.19);
}

[data-theme="light"] .translucent-style {
    background: hsla(0, 0%, 100%, 0.19);
}

/* */

.blur-shadow {
    position: absolute;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    z-index: -1;

    -webkit-box-shadow: 1px 1px 25px rgb(116 52 9 / 5%);
    box-shadow: 1px 1px 25px rgb(116 52 9 / 5%);
}

.blur-shadow.position-start {
    -webkit-transform: scale(0.75) translateX(-12px) !important;
    transform: scale(0.75) translateX(-12px) !important;
}

.blur-shadow.position-bottom {
    -webkit-transform: scale(0.85) translateY(4px) !important;
    transform: scale(0.85) translateY(4px) !important;
}

/* */

ion-button,
ion-chip,
ion-label,
ion-note,
ion-text,
ion-alert,
ion-toast,
ion-toolbar ion-title,
ion-action-sheet.menu-actions .action-sheet-group {
    font-family: "Mulish","sans-serif" !important;
}

ion-content {
    --background: transparent;
}

/* */

/* ion-tab-bar#mainTabBar {
    height: 56px;
} */

ion-tab-bar.hidden {
    display: none !important;
}

/* */

ion-segment.navigation {
    height: 48px;
}

ion-segment.navigation .col-img {
    padding-bottom: 0 !important;
}

ion-segment.navigation .col-text {
    padding-top: 0 !important;
}

ion-segment.navigation img {
    width: 26px;
}

ion-segment.navigation ion-label {
    margin-top: 0;
    margin-bottom: 6px;
    font-weight: 500;
    text-transform: capitalize;
}

ion-segment.navigation .segment-button-checked ion-label {
    color: var(--ion-color-primary) !important;
}

/* */

ion-action-sheet.menu-actions .action-sheet-group {
    background-clip: padding-box;
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
}

ion-action-sheet.menu-actions .action-sheet-button ion-icon.action-sheet-icon {
    -webkit-margin-end: 16px;
    margin-inline-end: 16px;
    font-size: 19px;
}

[data-theme="light"] ion-action-sheet.menu-actions .action-sheet-group {
    background: hsla(0, 0%, 100%, 0.9);
}

ion-action-sheet.menu-actions .action-sheet-button,
ion-action-sheet.menu-actions .action-sheet-button.ion-focused {
    color: var(--content-color) !important;
}

/* [data-theme="dark"] ion-action-sheet.menu-actions .action-sheet-button,
[data-theme="dark"] ion-action-sheet.menu-actions .action-sheet-button.ion-focused {
    color: #acacac;
}

[data-theme="light"] ion-action-sheet.menu-actions:not(.ios) .action-sheet-button,
[data-theme="light"] ion-action-sheet.menu-actions:not(.ios) .action-sheet-button.ion-focused {
    color: #222222;
} */

/* ion-action-sheet.menu-actions ion-backdrop {
    opacity: 0.5 !important;
} */

/*
ion-action-sheet.my-custom-class .action-sheet-cancel::after {
    background: #e97223;
}

@media (any-hover: hover) {
    ion-action-sheet.my-custom-class .action-sheet-button:hover {
        color: #000000;
    }
}

ion-action-sheet.my-custom-class ion-backdrop {
    opacity: 0.6;
} */

/* */

ion-alert ion-backdrop,
ion-modal::part(backdrop),
ion-popover::part(backdrop) {
    background-color: #000;
}

[data-theme="dark"] ion-alert ion-backdrop,
[data-theme="dark"] ion-modal::part(backdrop),
[data-theme="dark"] ion-popover::part(backdrop) {
    background-color: #444;
}

ion-popover::part(backdrop) {
    --backdrop-opacity: 0.5;
}

[data-theme="dark"] ion-popover::part(backdrop) {
    --backdrop-opacity: 0.6;
}

/*  */

ion-alert {
    --backdrop-opacity: 0.6 !important;
    --max-width: 300px !important;
}

.md ion-alert .alert-head {
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
    -webkit-padding-end: 20px;
    padding-inline-end: 20px;
}

ion-alert .alert-message {
    line-height: 1.5em !important;
}

.md ion-alert .alert-message {
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
    -webkit-padding-end: 20px;
    padding-inline-end: 20px;
}

ion-alert button.alert-button {
    text-transform: none !important;
}

.md ion-alert:not(.ios) button.alert-button {
    border-radius: 4px !important;
}

.ios ion-alert button.alert-button {
    border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2) !important;
}

ion-alert button.alert-button.alert-btn-cancel {
    color: var(--ion-color-light-contrast);
    background-color: var(--ion-color-light);
}

ion-alert button.alert-button.alert-btn-confirm {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);
}

/* */

ion-input {
    --border-radius: 4px !important;
    --padding-start: 16px !important;
    --padding-end: 16px !important;
}

[data-theme="dark"] ion-input {
    --background: #1e1e1e !important;
    --border-color: #898989 !important;
}

/* --border-color: var(--ion-color-step-500, gray) !important; */
[data-theme="light"] ion-input {
    --background: #f2f2f2 !important;
    --border-color: #989898 !important;
}

ion-input .input-wrapper {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
}

.input-label-placement-floating.has-focus .label-text-wrapper,
.input-label-placement-stacked.has-focus .label-text-wrapper {
    color: var(--highlight-color) !important;
}

/* */

ion-label:not(.title-color) {
    color: var(--content-color) !important;
}

/* [data-theme="dark"] ion-label:not(.title-color) {
    color: #acacac !important;
}

[data-theme="light"] ion-label:not(.title-color) {
    color: #222222 !important;
} */

ion-icon.cart,
ion-icon.cart svg {
    width: 1.6rem;
    height: 1.6rem;
}

/* */

/* .pf-toast-container .Toastify__toast {
    background-clip: padding-box !important;
    -webkit-backdrop-filter: blur(42px) !important;
    backdrop-filter: blur(42px) !important;
    font-family: "Mulish","sans-serif";
    font-weight: 500;
    z-index: 999;
}

[data-theme="dark"] .pf-toast-container .Toastify__toast {
    background: #ffffff !important;
    color: #121212;
}

[data-theme="light"] .pf-toast-container .Toastify__toast {
    background: #f58840 !important;
    color: #fefefe;
} */

/* */

.bs-bg-light-primary {
    background-color: rgba(0, 158, 247, 0.18) !important;
}

.bs-bg-light-danger {
    background-color: rgba(241, 65, 108, 0.18) !important;
}

.bs-bg-light-info {
    background-color: rgba(114, 57, 234, 0.18) !important;
}

.bs-bg-light-success {
    background-color: rgba(80, 205, 137, 0.18) !important;
}

.bs-bg-light-warning {
    background-color:  rgba(255, 199, 0, 0.18) !important;
}

/* */

.bg-soft-danger {
    background-color: rgba(240, 101, 72, 0.18) !important;
}

.bg-soft-primary {
    background-color: rgba(75, 56, 179, 0.18) !important;
}

.bg-soft-success {
    background-color: rgba(69, 203, 133, 0.18) !important;
}

.bg-soft-warning {
    background-color: rgba(255, 190, 11, 0.18)!important;
}
