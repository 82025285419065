@charset "UTF-8";

/* none, block, inline, table */

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

@media (min-width: 576px) {

    .d-sm-none {
        display: none !important;
    }

    .d-sm-block {
        display: block !important;
    }
    
    .d-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) {

    .d-md-none {
        display: none !important;
    }

    .d-md-block {
        display: block !important;
    }
    
    .d-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) {

    .d-lg-none {
        display: none !important;
    }

    .d-lg-block {
        display: block !important;
    }
    
    .d-lg-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {

    .d-xl-none {
        display: none !important;
    }

    .d-xl-block {
        display: block !important;
    }
    
    .d-xl-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1400px) {

    .d-xxl-none {
        display: none !important;
    }

    .d-xxl-block {
        display: block !important;
    }
    
    .d-xxl-inline-block {
        display: inline-block !important;
    }
}

/* flex */

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-center {
    justify-content: center !important;
    align-items: center !important;
}

.flex-stack {
    justify-content: space-between !important;
    align-items: center !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-root {
    flex: 1 !important;
}


.flex-column-fluid {
    flex: 1 0 auto !important;
}

.flex-row-fluid {
    flex: 1 auto !important;
    min-width: 0;
}

@media (min-width: 576px) {

    .d-sm-flex {
        display: flex !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-center {
        justify-content: center !important;
        align-items: center !important;
    }
    
    .flex-sm-stack {
        justify-content: space-between !important;
        align-items: center !important;
    }
    
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-root {
        flex: 1 !important;
    }

    .flex-sm-column-fluid {
        flex: 1 0 auto !important;
    }

    .flex-sm-row-fluid {
        flex: 1 auto !important;
        min-width: 0;
    }
}

@media (min-width: 768px) {

    .d-md-flex {
        display: flex !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-center {
        justify-content: center !important;
        align-items: center !important;
    }
    
    .flex-md-stack {
        justify-content: space-between !important;
        align-items: center !important;
    }
    
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-root {
        flex: 1 !important;
    }

    
    .flex-md-column-fluid {
        flex: 1 0 auto !important;
    }

    .flex-md-row-fluid {
        flex: 1 auto !important;
        min-width: 0;
    }
}

@media (min-width: 992px) {

    .d-lg-flex {
        display: flex !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-center {
        justify-content: center !important;
        align-items: center !important;
    }
    
    .flex-lg-stack {
        justify-content: space-between !important;
        align-items: center !important;
    }
    
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-root {
        flex: 1 !important;
    }

    .flex-lg-column-fluid {
        flex: 1 0 auto !important;
    }

    .flex-lg-row-fluid {
        flex: 1 auto !important;
        min-width: 0;
    }
}

@media (min-width: 1200px) {

    .d-xl-flex {
        display: flex !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-center {
        justify-content: center !important;
        align-items: center !important;
    }
    
    .flex-xl-stack {
        justify-content: space-between !important;
        align-items: center !important;
    }
    
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-root {
        flex: 1 !important;
    }

    .flex-xl-column-fluid {
        flex: 1 0 auto !important;
    }

    .flex-xl-row-fluid {
        flex: 1 auto !important;
        min-width: 0;
    }
}

@media (min-width: 1400px) {

    .d-xxl-flex {
        display: flex !important;
    }

    .flex-xxl-column {
        flex-direction: column !important;
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xxl-row {
        flex-direction: row !important;
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xxl-center {
        justify-content: center !important;
        align-items: center !important;
    }
    
    .flex-xxl-stack {
        justify-content: space-between !important;
        align-items: center !important;
    }
    
    .flex-xxl-wrap {
        flex-wrap: wrap !important;
    }
    
    .flex-xxl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xxl-root {
        flex: 1 !important;
    }

    .flex-xxl-column-fluid {
        flex: 1 0 auto !important;
    }

    .flex-xxl-row-fluid {
        flex: 1 auto !important;
        min-width: 0;
    }
}

/* align-items */

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-initial {
    align-items: initial !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

@media (min-width: 576px) {

    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    
    .align-items-sm-center {
        align-items: center !important;
    }
    
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    
    .align-items-sm-initial {
        align-items: initial !important;
    }
    
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
}

@media (min-width: 768px) {

    .align-items-md-baseline {
        align-items: baseline !important;
    }
    
    .align-items-md-center {
        align-items: center !important;
    }
    
    .align-items-md-end {
        align-items: flex-end !important;
    }
    
    .align-items-md-initial {
        align-items: initial !important;
    }
    
    .align-items-md-start {
        align-items: flex-start !important;
    }
    
    .align-items-md-stretch {
        align-items: stretch !important;
    }
}

@media (min-width: 992px) {

    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    
    .align-items-lg-center {
        align-items: center !important;
    }
    
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    
    .align-items-lg-initial {
        align-items: initial !important;
    }
    
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
}

@media (min-width: 1200px) {

    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    
    .align-items-xl-center {
        align-items: center !important;
    }
    
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    
    .align-items-xl-initial {
        align-items: initial !important;
    }
    
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
}

@media (min-width: 1400px) {

    .align-items-xxl-baseline {
        align-items: baseline !important;
    }
    
    .align-items-xxl-center {
        align-items: center !important;
    }
    
    .align-items-xxl-end {
        align-items: flex-end !important;
    }
    
    .align-items-xxl-initial {
        align-items: initial !important;
    }
    
    .align-items-xxl-start {
        align-items: flex-start !important;
    }
    
    .align-items-xxl-stretch {
        align-items: stretch !important;
    }
}

/* justify-content */

.justify-content-baseline {
    justify-content: baseline !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-initial {
    justify-content: initial !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-stretch {
    justify-content: stretch !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

@media (min-width: 576px) {

    .justify-content-sm-baseline {
        justify-content: baseline !important;
    }
    
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    
    .justify-content-sm-center {
        justify-content: center !important;
    }
    
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    
    .justify-content-sm-initial {
        justify-content: initial !important;
    }
    
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    
    .justify-content-sm-stretch {
        justify-content: stretch !important;
    }
    
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    
    .justify-content-sm-evenly {
        justify-content: space-evenly !important;
    }
}

@media (min-width: 768px) {

    .justify-content-md-baseline {
        justify-content: baseline !important;
    }
    
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    
    .justify-content-md-center {
        justify-content: center !important;
    }
    
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    
    .justify-content-md-initial {
        justify-content: initial !important;
    }
    
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    
    .justify-content-md-stretch {
        justify-content: stretch !important;
    }
    
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    
    .justify-content-md-evenly {
        justify-content: space-evenly !important;
    }
}

@media (min-width: 992px) {

    .justify-content-lg-baseline {
        justify-content: baseline !important;
    }
    
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    
    .justify-content-lg-center {
        justify-content: center !important;
    }
    
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    
    .justify-content-lg-initial {
        justify-content: initial !important;
    }
    
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    
    .justify-content-lg-stretch {
        justify-content: stretch !important;
    }
    
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    
    .justify-content-lg-evenly {
        justify-content: space-evenly !important;
    }
}

@media (min-width: 1200px) {

    .justify-content-xl-baseline {
        justify-content: baseline !important;
    }
    
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    
    .justify-content-xl-center {
        justify-content: center !important;
    }
    
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    
    .justify-content-xl-initial {
        justify-content: initial !important;
    }
    
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    
    .justify-content-xl-stretch {
        justify-content: stretch !important;
    }
    
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    
    .justify-content-xl-evenly {
        justify-content: space-evenly !important;
    }
}

@media (min-width: 1400px) {

    .justify-content-xxl-baseline {
        justify-content: baseline !important;
    }
    
    .justify-content-xxl-between {
        justify-content: space-between !important;
    }
    
    .justify-content-xxl-center {
        justify-content: center !important;
    }
    
    .justify-content-xxl-end {
        justify-content: flex-end !important;
    }
    
    .justify-content-xxl-initial {
        justify-content: initial !important;
    }
    
    .justify-content-xxl-start {
        justify-content: flex-start !important;
    }
    
    .justify-content-xxl-stretch {
        justify-content: stretch !important;
    }
    
    .justify-content-xxl-around {
        justify-content: space-around !important;
    }
    
    .justify-content-xxl-evenly {
        justify-content: space-evenly !important;
    }
}
