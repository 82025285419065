@charset "UTF-8";

/* font-size */

.fs-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
    font-size: 1.25rem !important;
}

.fs-5 {
    font-size: 1.15rem !important;
}

.fs-6 {
    font-size: 1.075rem !important;
}

.fs-7 {
    font-size: 0.95rem !important;
}

.fs-8 {
    font-size: 0.85rem !important;
}

.fs-9 {
    font-size: 0.75rem !important;
}

.fs-10 {
    font-size: 0.5rem !important;
}

.fs-base {
    font-size: 1rem !important;
}

/* font-weight */

.fw-100,
.fw-thin
{
    font-weight: 100 !important;
}

.fw-200,
.fw-extralight
{
    font-weight: 200 !important;
}

.fw-300,
.fw-light
{
    font-weight: 300 !important;
}

.fw-400,
.fw-normal
{
    font-weight: 400 !important;
}

.fw-500,
.fw-medium
{
    font-weight: 500 !important;
}

.fw-600,
.fw-semibold
{
    font-weight: 600 !important;
}

.fw-700,
.fw-bold
{
    font-weight: 700 !important;
}

.fw-800,
.fw-extrabold
{
    font-weight: 800 !important;
}

.fw-900,
.fw-fat
{
    font-weight: 900 !important;
}

.fw-bolder
{
    font-weight: bolder !important;
}

.fw-lighter
{
    font-weight: lighter !important;
}
