@charset "UTF-8";

/* */

.cart-checkout-button .btn-text {
    font-weight: 500;
    font-family: "Mulish","sans-serif";
    color: #fff;
    text-transform: none;
}
