@charset "UTF-8";

/* */

.box-type-details {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 calc(2rem * 0.5) calc(0.5rem * 0.5) calc(2rem * 0.5);
    border-radius: 0 !important;
    contain: layout;
    overflow: visible;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
}

/* .box-type-details::after {
    left: 0;
    bottom: -5px;
    background-position: left 0 top -2px;
    position: absolute;
    width: 100%;
    height: 5px;
    --background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
    background-repeat: repeat-x;
    content: "";
} */

.box-type-details .box-type-image-wrapper {
    position: relative;
    display: flex;
    padding: 0;
}

.box-type-details .box-type-image-wrapper img {
    width: 64px;
    height: 64px;
    max-width: 64px;
    max-height: 64px;
    -o-object-fit: contain;
    object-fit: contain;
    margin: auto;
    border-radius: 50%;
    text-align: center;
    z-index: 10;
}

@media (min-width: 576px){

    .box-type-details .box-type-image-wrapper img {
        width: 72px;
        height: 72px;
        max-width: 72px;
        max-height: 72px;
    }
}

@media (min-width: 768px){

    .box-type-details .box-type-image-wrapper img {
        width: 76px;
        height: 76px;
        max-width: 76px;
        max-height: 76px;
    }
}

@media (min-width: 992px){

    .box-type-details .box-type-image-wrapper img {
        width: 80px;
        height: 80px;
        max-width: 76px;
        max-height: 76px;
    }
}

@media (min-width: 1200px){

    .box-type-details .box-type-image-wrapper img {
        width: 88px;
        height: 88px;
        max-width: 88px;
        max-height: 88px;
    }
}

.box-type-details .box-type-image:hover {
    /* -webkit-animation: 14s cubic-bezier(0.26, 0.26, 1, 1) 0s infinite normal none running rotateSmooth; */
    /* animation: 14s cubic-bezier(0.26, 0.26, 1, 1) 0s infinite normal none running rotateSmooth; */

    -webkit-animation-duration: 14s;
    -webkit-animation-timing-function: cubic-bezier(0.26, 0.26, 1, 1);
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-fill-mode: none;
    -webkit-animation-play-state: running;
    /* -webkit-animation: rotateSmooth; */

    animation-duration: 14s;
    animation-timing-function: cubic-bezier(0.26, 0.26, 1, 1);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: rotateSmooth;
}

/* */

.box-type-details .box-type-details-content {
    padding: 0;
}

.box-type-details .box-type-details-content .btn-show-menu,
.box-type-details .box-type-details-content .btn-reset-cart,
.box-type-details .box-type-details-content .btn-add-to-cart {
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --border-radius: 4px;
}

.box-type-details .choice-infos,
.box-type-details .box-type-slices-number,
.box-type-details .box-type-details-content .btn-show-menu,
.box-type-details .box-type-details-content .btn-reset-cart,
.box-type-details .box-type-details-content .btn-add-to-cart {
    font-family: "Mulish","sans-serif";
}

.box-type-details .box-type-slices-number {
    font-weight: 600;
}

/* .box-type-details .choice-infos {
    width: 140px;
} */

/* @media (min-width: 400px) {
    .box-type-details .choice-infos {
        width: 140px;
    }
}

@media (min-width: 420px) {
    .box-type-details .choice-infos {
        width: 220px;
    }
}

@media (min-width: 480px) {
    .box-type-details .choice-infos {
        width: 250px;
    }
}

@media (min-width: 576px) {
    .box-type-details .choice-infos {
        width: 300px;
    }
} */
