@charset "UTF-8";

/* */

.auth-page-content.ion-padding {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* @media only screen and (min-height: 800px) {

    .auth-page-content-container {
        margin-top: -120px;
    }
} */

/* */

.auth-page-content .form-card {
    font-family: "Mulish", "sans-serif" !important;
    border-radius: 10px !important;
}

.auth-page-content .form-card .form-card-content {
    padding-inline-end: 16px;
    padding-inline-start: 16px;
}

@media (min-width: 400px){

    .auth-page-content .form-card .form-card-content {
        padding-inline-end: 20px;
        padding-inline-start: 20px;
    }
}

@media (min-width: 576px){

    .auth-page-content .form-card .form-card-content {
        padding-inline-end: 32px;
        padding-inline-start: 32px;
    }
}

@media (min-width: 768px){

    .auth-page-content .form-card .form-card-content {
        padding-inline-end: 48px;
        padding-inline-start: 48px;
    }
}

/* */

.auth-page-content .form-card .form-element {
    font-family: inherit;
    font-weight: 500;
}

.auth-page-content .form-card ion-input.form-element {
    min-height: 52px !important;
    --border-radius: 0.375rem !important;
}

[data-theme='dark'] .auth-page-content .form-card ion-input.form-element[fill='solid'].has-focus {
   --background: var(--ion-color-step-50, #f2f2f2) !important;
}

[data-theme='light'] .auth-page-content .form-card ion-input.form-element[fill='solid'].has-focus {
   --background: var(--ion-color-step-50, #f9f9f9) !important;
}

.auth-page-content .form-card ion-button.form-element[fill='solid'] {
    font-weight: 600;
}

.auth-page-content .form-card ion-button:not(.password-visibility-toggler)[fill='clear']::part(native) {
    padding: 0 !important;
}

.auth-page-content .form-card ion-button[type='submit']::part(native) {
    color: #fff !important;
}
